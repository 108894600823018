<template>
  <div class="card">
    <div class="card-header" style="display: flex; justify-content: space-between; align-items: center; padding: 8px 20px;">

      <h4 class="card-title" style="position: relative; top: 2px;">
        기존 바코드 검색
      </h4>

      <SrchInput/>

    </div>

    <div class="card-body" style="padding: 20px 20px 0 20px;">
      <div class="row">
<!--        <button @click="test">테스트</button>-->
        <SrchCell v-for="row in srchBarcodeList" :key="row.barcode" :row="row" class="col-6"/>

        <div v-if="srchBarcodeList.length === 0" style="display: flex; justify-content: center; padding-bottom: 20px; height: 200px;">
          <h6 class="m-0" style="display: flex; flex-direction: column; align-items: center; justify-content: center">기존 바코드를 검색해주세요</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SrchCell from "@/views/(duplicate)/customBarcode/atoms/srchCell.vue";
import SrchInput from "@/views/(duplicate)/customBarcode/atoms/srchInput.vue";
import {mapState} from "vuex";
import {customBarcodeState} from "@/state/modules/customBarcodeHelper";

export default {
  name: "searchBarcode",
  components: {SrchInput, SrchCell},
  data() {
    return {
      tmpList: this.srchBarcodeList
    }
  },
  computed: {
    ...mapState(customBarcodeState),
  },
  methods: {
    test() {
      console.log('dfdfsfdsfsd', this.tmpList, this.srchBarcodeList)
    }
  }
}
</script>

<style scoped>

</style>
