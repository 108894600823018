<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import RegisterBarcode from "@/views/(duplicate)/customBarcode/module/register/registerBarcode.vue";
import SearchBarcode from "@/views/(duplicate)/customBarcode/module/searchBarcode.vue";

export default {
  page: {
    title: "자체 바코드",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "자체 바코드",

    };
  },
  components: {
    SearchBarcode,
    RegisterBarcode,
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-4">
        <RegisterBarcode/>
      </div>

      <div class="col-8 mb-3">
        <SearchBarcode/>
      </div>
    </div>
  </Layout>
</template>
